import React, { useEffect } from 'react';
import JMuxer from 'jmuxer';
import lib from '@equal/lib';
import {getCLient} from "./funcs";



let client = null

const App = () => {
  async function start() {
    client = await getCLient()
     await client.init()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.getElementById('player').addEventListener('click', async function (e) {
      const jmuxer = new JMuxer({
        node: 'player',
        mode: 'video', /* available values are: both, audio and video */
        debug: true,
        clearBuffer: false,
        // flushingTime: 1000,
        fps: 30,
      })

      await client.read("key",
          "2e1fabc61b4e5b155cd561a8f2b628a58e953f03c0166f04dc981619760a1d44",
          "video", null,
          (uint8Array) => {
            console.log("OnWrite")
            jmuxer.feed({
              video: uint8Array,
            });
          })
    })
  }

  return (
      <>
        <div>
          <div id="containerVideo">
            <video autoPlay={true} controls={true} id="player" onClick={start}></video>
          </div>
          {/*<br/>*/}
          {/*<button id="play-btn" onClick={start}>Start streaming</button>*/}
        </div>
      </>
  )
}



export default App;
