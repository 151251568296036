import {Client} from '@equal/lib'

let client = null
let publicKey = null
let privateKey = null

const initClient = async () => {
    if (client) {
        try {
            client.touch()
        } catch (err) {
            window.localStorage.removeItem('publicKey')
            window.localStorage.removeItem('privateKey')
            console.log('Touch error: ', err)
        }
        return client
    } else {
        const host = `${process.env.NEXT_PUBLIC_HOST}${
            process.env.NEXT_PUBLIC_URL ||
            window.location.host + process.env.NEXT_PUBLIC_EXTERNAL_ASSETS_HOST
        }`

        client = new Client(
          "ws://localhost",
          80,
          '5A5D7CE2E00EB85A9FF9C2B9375D7393F281A54C6E4E806F9FCEFA6488656B53',
          1000
      );
        publicKey = window.localStorage?.getItem('publicKey')
        privateKey = window.localStorage?.getItem('privateKey')

        if (privateKey && publicKey) {
            await client.init(publicKey, privateKey)
            try {
                await client.touch()
            } catch (err) {
                window.localStorage.removeItem('publicKey')
                window.localStorage.removeItem('privateKey')
                if (window.location.pathname !== '/') {
                    window.location.href = '/'
                }
                console.log('Re-initial error: ', err)
                return client
            }
            // client.setDefaultLogLevel('error')
        } else if (window.location.pathname !== '/') {
            window.location.href = '/'
        }
        return client
    }
}

export const getCLient = async() => {
    return await initClient()
}
